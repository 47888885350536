<template>
  <div v-show="(visualizacao == 1)" class="banner w-full homepage-margin" :style="'background-image: url(' + imagem + ');'">
    <div class="flex flex-column w-full">
      <div class="banner-text w-full">
        <div class="banner-text-titulo flex flex-column fadeinup animation-duration-1000" v-html="titulo"></div>
        <div class="flex gap-0">
          <!-- <div class="banner-text-titulo hidden sm:flex flex-column flex-grow-0 opacity-0" v-html="tituloLastDiv"></div> -->
          <div class="banner-text-subtitulo flex flex-column flex-grow-1 fadeinleft animation-duration-1000" v-html="subtitulo"></div>
        </div>
      </div>
      <div class="banner-focoEmpresa flex w-full justify-content-end fadeinright animation-duration-2000">
        <div @click="fc_goRoute('focoEmpresa')" class="banner-focoEmpresa-box cursor-pointer">
          <div class="banner-focoEmpresa-boxTexto">
            <span class="pi pi-fw pi-angle-right banner-focoEmpresa-icon"></span><span class="banner-focoEmpresa-box-txt" v-html="fc_getText(null, 'focoEmpresa', { upper: true })"></span>
          </div>
        </div>
      </div>
      <!-- <div class="banner-registosNotariado flex w-full justify-content-end fadeinright animation-duration-2000">
        <div @click="fc_goRoute('registosNotariado')" class="banner-registosNotariado-box cursor-pointer">
          <div class="banner-registosNotariado-boxTexto">
            <span class="pi pi-fw pi-angle-right banner-registosNotariado-icon"></span><span class="banner-registosNotariado-box-txt " v-html="fc_getText(null, 'registosNotariado', { upper: true })"></span>
          </div>
        </div>
      </div> -->
      <div class="banner-nextlaw flex w-full justify-content-end fadeinright animation-duration-2000">
        <div @click="fc_goRoute('nextlaw')" class="banner-nextlaw-box cursor-pointer">
          <div class="banner-nextlaw-box-img"></div>
        </div>
      </div>
    </div>
  </div>
  <div v-show="(visualizacao == 2)" class="banner w-full homepage-margin">
    <div class="teste" v-if="(!videoLoaded)"><AppSpinner class="teste2" /></div>
    <video muted autoplay loop :src="video" type="video/mp4"></video>
    <div v-if="(videoLoaded)" class="flex flex-column w-full">
      <div class="banner-text w-full">
        <div class="banner-text-titulo flex flex-column fadeinup animation-duration-1000" v-html="titulo"></div>
        <div class="flex gap-0">
          <!-- <div class="banner-text-titulo hidden sm:flex flex-column flex-grow-0 opacity-0" v-html="tituloLastDiv"></div> -->
          <div class="banner-text-subtitulo flex flex-column flex-grow-1 fadeinleft animation-duration-1000" v-html="subtitulo"></div>
        </div>
      </div>
      <div class="banner-focoEmpresa flex w-full justify-content-end fadeinright animation-duration-2000">
        <div @click="fc_goRoute('focoEmpresa')" class="banner-focoEmpresa-box cursor-pointer">
          <div class="banner-focoEmpresa-boxTexto">
            <span class="pi pi-fw pi-angle-right banner-focoEmpresa-icon"></span><span class="banner-focoEmpresa-box-txt" v-html="fc_getText(null, 'focoEmpresa', { upper: true })"></span>
          </div>
        </div>
      </div>
      <!-- <div class="banner-registosNotariado flex w-full justify-content-end fadeinright animation-duration-2000">
        <div @click="fc_goRoute('registosNotariado')" class="banner-registosNotariado-box cursor-pointer">
          <div class="banner-registosNotariado-boxTexto">
            <span class="pi pi-fw pi-angle-right banner-registosNotariado-icon"></span><span class="banner-registosNotariado-box-txt " v-html="fc_getText(null, 'registosNotariado', { upper: true })"></span>
          </div>
        </div>
      </div> -->
      <div class="banner-nextlaw flex w-full justify-content-end fadeinright animation-duration-2000">
        <div @click="fc_goRoute('nextlaw')" class="banner-nextlaw-box cursor-pointer">
          <div class="banner-nextlaw-box-img"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loaded: false,
      videoLoaded:false
    };
  },
  props: {
    titulo: String,
    subtitulo: String,
    visualizacao: Number,
    imagem: String,
    video: String,
    id: Number
  },
  computed: {
    tituloLastDiv() {
      const divs = this.titulo.split('<div>');
      if (divs.length > 1) {
        return '<div>' + divs[divs.length - 1] + '';
      } else {
        return this.titulo;
      }
    },
  },
  watch: {
    "id"(val) {
      if (this.video != null) {
        this.videoLoaded = false;
        const videoSelect = document.querySelector("video");

        videoSelect.addEventListener("loadeddata", (event) => {
          this.videoLoaded = true;
        });
      }
    }
  },
  methods: {},
  created() {},
  mounted() {
    if (this.video != null) {
      this.videoLoaded = false;
      const videoSelect = document.querySelector("video");
      videoSelect.addEventListener("loadeddata", (event) => {
        this.videoLoaded = true;
      });
    }
  },
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.banner {
  height: 70vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.banner video {
  height:100%;
  width:100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  position: absolute;
  z-index: -1
 }

.banner .banner-text {
  padding-top: 18vh;
  padding-right: 8vw;
}

.banner .banner-text .banner-text-titulo {
  color: #fff;
  font-family: var(--rsa-font-zilla-semibold);
  font-size: 36px;
}
.banner .banner-text .banner-text-subtitulo {
  color: #fff;
  padding: 10px 0px;
  font-size: 18px;
  padding-left: 6vw;
}

@media screen and (max-width: 576px) {
  .banner .banner-text .banner-text-titulo:deep(div > span) {
    padding-left: 6vw;
  }
  .banner .banner-text .banner-text-titulo {
    font-size: 26px;
  }
  .banner .banner-text .banner-text-subtitulo {
    font-size: 16px;
  }
}
@media screen and (max-width: 576px) and (max-height:600px) {
  .banner .banner-text .banner-text-titulo {
    font-size: 24px;
  }
  .banner .banner-text .banner-text-titulo:deep(div > span) {
    padding-left: 6vw;
  }
  .banner .banner-text .banner-text-subtitulo {
    font-size: 10px;
    padding-left: 6vw;
  }
}
@media screen and (min-width: 576px) and (max-height:600px) {
  .banner .banner-text .banner-text-titulo {
    font-size: 20px;
  }
  .banner .banner-text .banner-text-titulo:deep(div > span) {
    padding-left: 6vw;
  }
  .banner .banner-text .banner-text-subtitulo {
    font-size: 12px;
    padding-left: 0vw;
    padding-left: 6vw;
  }
}
@media screen and (min-width: 576px) and (min-height:600px) {
  .banner .banner-text .banner-text-titulo {
    font-size: 36px;
  }
  .banner .banner-text .banner-text-titulo:deep(div > span) {
    padding-left: 6vw;
  }
  .banner .banner-text .banner-text-subtitulo {
    font-size: 16px;
    padding-left: 6vw;
  }
}
@media screen and (min-width: 768px) and (max-height:600px) {
  .banner .banner-text .banner-text-titulo {
    font-size: 22px;
  }
  .banner .banner-text .banner-text-titulo:deep(div > span) {
    padding-left: 6vw;
  }
  .banner .banner-text .banner-text-subtitulo {
    font-size: 15px;
    padding-left: 6vw;
  }
}
@media screen and (min-width: 768px) and (min-height:600px) {
  .banner .banner-text .banner-text-titulo {
    font-size: 40px;
  }
  .banner .banner-text .banner-text-subtitulo {
    font-size:20px;
    padding-left: 6vw;
  }
}
@media screen and (min-width: 992px) and (max-height:600px) {
  .banner .banner-text .banner-text-titulo {
    font-size: 25px;
  }
  .banner .banner-text .banner-text-titulo:deep(div > span) {
    padding-left: 310px;
  }
  .banner .banner-text .banner-text-subtitulo {
    font-size: 15px;
    padding-left: 315px;
  }
}
@media screen and (min-width: 992px) and (min-height:600px) {
  .banner .banner-text .banner-text-titulo {
    font-size: 45px;
  }
  .banner .banner-text .banner-text-titulo:deep(div > span) {
    padding-left: 315px;
  }
  .banner .banner-text .banner-text-subtitulo {
    font-size: 20px;
    padding-left: 315px;
  }
}
@media screen and (min-width: 1200px) and (max-height:600px) {
  .banner .banner-text .banner-text-titulo {
    font-size: 30px;
  }
  .banner .banner-text .banner-text-titulo:deep(div > span) {
    padding-left: 310px;
  }
  .banner .banner-text .banner-text-subtitulo {
    font-size: 16px;
    padding-left: 315px;
  }
}
@media screen and (min-width: 1200px) and (min-height:600px) {
  .banner .banner-text .banner-text-titulo {
    font-size: 40px;
  }
  .banner .banner-text .banner-text-subtitulo {
    font-size: 18px;
    padding-left: 315px;
  }
}
@media screen and (min-width: 1200px) and (min-height:800px) {
  .banner .banner-text .banner-text-titulo {
    font-size: 55px;
  }
  .banner .banner-text .banner-text-subtitulo {
    font-size: 24px;
    padding-left: 315px;
  }
}
@media screen and (min-width: 2300px) and (min-height:800px) {
  .banner .banner-text .banner-text-titulo {
    font-size: 70px;
  }
  .banner .banner-text .banner-text-subtitulo {
    font-size: 34px;
    padding-left: 315px;
  }
}
.banner .banner-text .banner-text-titulo:deep(div) {
  margin-top: -8px;
}
.banner .banner-text .banner-text-titulo:deep(div > span) {
  white-space: nowrap;
}
.banner .banner-text .banner-text-titulo:deep(div:last-child > span) {
  border-bottom: 1px solid #fff;
}
/* @media screen and (min-width: 576px) {
  .banner .banner-text .banner-text-subtitulo {
    font-size: 18px;
    padding-left: 0vw;
  }
} */
/* @media screen and (min-width: 768px) {
  .banner .banner-text .banner-text-subtitulo {
    font-size: 22px;
    padding-left: 0vw;
  }
} */
/* @media screen and (min-width: 992px) {
  .banner .banner-text .banner-text-subtitulo {
    font-size: 24px;
    padding-left: 0vw;
  }
} */
/* @media screen and (min-width: 1200px) {
  .banner .banner-text .banner-text-subtitulo {
    font-size: 26px;
    padding-left: 0vw;
  }
} */

.banner .banner-nextlaw {
  position: absolute;
  bottom: -56px;
}
.banner .banner-focoEmpresa {
  position: absolute;
  bottom: 120px;
}
.banner .banner-registosNotariado {
  position: absolute;
  bottom: 65px;
}
.banner .banner-nextlaw-box,
.banner .banner-focoEmpresa-box,
.banner .banner-registosNotariado-box {
  width: 60vw;
  height: 44px;
  padding: 12px 15px;
}
.banner .banner-nextlaw-box {
  background-color: transparent;
}
.banner .banner-focoEmpresa-box,
.banner .banner-registosNotariado-box {
  background-color: #000;
}

.banner-focoEmpresa-boxTexto,
.banner-registosNotariado-boxTexto {
  width:100%;
  height:100%;
  display:flex;
  align-items:center;
}

.banner-focoEmpresa-box-txt,
.banner-registosNotariado-box-txt {
  color: #fff;
}
.banner-focoEmpresa-icon,
.banner-registosNotariado-icon {
    color: #00aeef;
    font-size:19px;
}

@media screen and (max-width: 576px) and (min-height:600px) {
  .banner .banner-focoEmpresa {
    position: absolute;
    bottom: 90px;
  }
  .banner .banner-registosNotariado {
    position: absolute;
    bottom: 45px;
  }
  .banner .banner-nextlaw-box,
  .banner .banner-focoEmpresa-box,
  .banner .banner-registosNotariado-box {
    width: 50vw;
  }
  .banner .banner-nextlaw-box {
    height:50px;
  }
  .banner .banner-focoEmpresa-box,
  .banner .banner-registosNotariado-box {
    height:36px;
  }
  .banner-focoEmpresa-box-txt,
  .banner-registosNotariado-box-txt {
    font-size:12px;
  }
}
@media screen and (min-width: 576px) and (min-height:600px) {
  .banner .banner-nextlaw-box,
  .banner .banner-focoEmpresa-box,
  .banner .banner-registosNotariado-box {
    width: 45vw;
  }
}
@media screen and (max-width: 576px) and (max-height:600px) {
  .banner .banner-focoEmpresa {
    position: absolute;
    bottom: 85px;
  }
  .banner .banner-registosNotariado {
    position: absolute;
    bottom: 45px;
  }
  .banner .banner-nextlaw-box,
  .banner .banner-focoEmpresa-box,
  .banner .banner-registosNotariado-box {
    width: 40vw;
  }
  .banner .banner-nextlaw-box {
    height:45px;
  }
  .banner .banner-focoEmpresa-box,
  .banner .banner-registosNotariado-box {
    height:36px;
  }
  .banner-focoEmpresa-box-txt,
  .banner-registosNotariado-box-txt {
    font-size:12px;
  }
}
@media screen and (min-width: 576px) and (max-height:600px) {
  .banner .banner-nextlaw-box,
  .banner .banner-focoEmpresa-box,
  .banner .banner-registosNotariado-box {
    width: 33vw;
  }
  .banner .banner-nextlaw-box {
    height: 47px;
  }
  .banner .banner-focoEmpresa-box,
  .banner .banner-registosNotariado-box {
    height: 40px;
    padding: 16px 15px;
  }
}
@media screen and (min-width: 768px) and (min-height:600px) {
  .banner .banner-nextlaw-box {
    height: 56px;
  }
  .banner .banner-nextlaw-box,
  .banner .banner-focoEmpresa-box,
  .banner .banner-registosNotariado-box {
    width: 35vw;
  }
}

@media screen and (min-width: 768px) and (max-height:600px) {
  .banner .banner-nextlaw-box,
  .banner .banner-focoEmpresa-box,
  .banner .banner-registosNotariado-box {
    width: 30vw;
  }
  .banner .banner-nextlaw-box {
    height: 56px;
  }
  .banner .banner-focoEmpresa-box,
  .banner .banner-registosNotariado-box {
    height: 50px;
  }
}
@media screen and (min-width: 992px) and (min-height:600px) {
  .banner .banner-nextlaw-box,
  .banner .banner-focoEmpresa-box,
  .banner .banner-registosNotariado-box {
    width: 25vw;
  }
}
@media screen and (min-width: 992px) and (max-height:600px) {
  .banner .banner-nextlaw-box,
  .banner .banner-focoEmpresa-box,
  .banner .banner-registosNotariado-box {
    width: 18vw;
    height: 50px;
  }
}
@media screen and (min-width: 1200px) {
  .banner .banner-nextlaw-box,
  .banner .banner-focoEmpresa-box,
  .banner .banner-registosNotariado-box {
    width: 18vw;
    height: 46px;
  }
}

.banner .banner-nextlaw-box-img {
  width: 100%;
  height: 100%;
  background-image: url('@/assets/design/nextlaw_black.png');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  transition: all 0.3s ease 0s;
}

.banner .banner-nextlaw-box:hover .banner-nextlaw-box-img {
  transform: translateX(6%);
  transition: all 0.3s ease 0s;
}
.banner .banner-focoEmpresa-box div,
.banner .banner-registosNotariado-box div {
  transition: all 0.3s ease 0s;
}
.banner .banner-focoEmpresa-box:hover div,
.banner .banner-registosNotariado-box:hover div {
  transform: translateX(6%);
  transition: all 0.3s ease 0s;
}
</style>
