<template>
<div class="w-full">
  <Menubar :model="items" class="menuPrincipal">
    <template #item="{ item, props, root, hasSubmenu}">
      <a v-bind="props.action" :class="(item.code == 'areasGroup' ? 'areasGroup' : (item.code == 'areaList' ? 'areaList' : (item.code == 'focoEmpresa' || item.code == 'registosNotariado' ? 'servicosList' : '')))">
        <span v-bind="props.label" v-html="item.label"></span>
        <span :class="[hasSubmenu && (root ? 'pi pi-fw pi-angle-down' : 'pi pi-fw pi-angle-right')]" v-bind="props.submenuicon" />
      </a>
    </template>
    <template #end>
      <div class="flex align-items-center">
        <div class="pl-2 pr-2 rsa-color-02">|</div>
        <MenuIdioma />
        <Button @click="mt_search()" icon="pi pi-search" text rounded class="button-icon-white" />
      </div>
    </template>
  </Menubar>
</div>
</template>

<script>
import Menubar from 'primevue/menubar';
import MenuIdioma from '@/components/MenuIdioma.vue';
import MenuPesquisa from '@/components/MenuPesquisa.vue';
export default {
  components: {
    Menubar,
    MenuIdioma,
    MenuPesquisa
  },
  data() {
    return {
      items: [
        {
          code: 'sobreNos',
          label: this.fc_getText(null, 'sobreNos', { upper: true }),
          items: [
            {
              code: 'rsa',
              label: this.fc_getText(null, 'rsa', { upper: true }),
              command: () => { this.fc_goRoute('rsa'); },
              disabled: () => { return (this.$route.name == 'rsa'); },
            },
            {
              code: 'premios',
              label: this.fc_getText(null, 'premios', { title: true }),
              command: () => { this.fc_goRoute('premios'); },
              disabled: () => { return (this.$route.name == 'premios'); },
            },
            {
              code: 'rsol',
              label: this.fc_getText(null, 'rsol', { upper: true }),
              command: () => { this.fc_goRoute('rsol'); },
              disabled: () => { return (this.$route.name == 'rsol'); },
            },
            {
              code: 'recrutamento',
              label: this.fc_getText(null, 'recrutamento', { title: true }),
              command: () => { this.fc_goRoute('recrutamento'); },
              disabled: () => { return (this.$route.name == 'recrutamento'); },
            },
            {
              code: 'newsletters',
              label: this.fc_getText(null, 'newsletters', { title: true }),
              command: () => { this.fc_goRoute('newsletters'); },
              disabled: () => { return (this.$route.name == 'newsletters'); },
            },
          ],
        },
        {
          code: 'rede',
          label: this.fc_getText(null, 'rede', { upper: true }),
          items: [],
        },
        {
          code: 'areas',
          label: this.fc_getText(null, 'areas', { upper: true }),
          items: []
        },
        {
          code: 'equipa',
          label: this.fc_getText(null, 'equipa', { upper: true }),
          command: () => { this.fc_goRoute('equipa'); },
          disabled: () => { return (this.$route.name == 'equipa'); },
        },
        {
          code: 'conhecimento',
          label: this.fc_getText(null, 'conhecimento', { upper: true }),
          command: () => { this.fc_goRoute('conhecimento'); },
          items: [
            {
              code: 'eventos',
              label: this.fc_getText(null, 'eventos', { title: true }),
              command: () => { this.fc_goRoute('eventos'); },
              disabled: () => { return (this.$route.name == 'eventos'); },
            },
            {
              code: 'noticias',
              label: this.fc_getText(null, 'noticiasImprensa', { title: true }),
              command: () => { this.fc_goRoute('noticias'); },
              disabled: () => { return (this.$route.name == 'noticias'); },
            },
            {
              code: 'videos',
              label: this.fc_getText(null, 'videos', { title: true }),
              command: () => { this.fc_goRoute('videos'); },
              disabled: () => { return (this.$route.name == 'videos'); },
            },
            {
              code: 'publicacoes',
              label: this.fc_getText(null, 'publicacoes', { title: true }),
              command: () => { this.fc_goRoute('publicacoes'); },
              items: [
                // {
                //   code: 'newsletters',
                //   label: this.fc_getText(null, 'newsletters', { title: true }),
                //   command: () => { this.fc_goRoute('newsletters'); },
                //   disabled: () => { return (this.$route.name == 'newsletters'); },
                // },
                {
                  code: 'legalAlerts',
                  label: this.fc_getText(null, 'legalAlerts', { title: true }),
                  command: () => { this.fc_goRoute('legalAlerts'); },
                  disabled: () => { return (this.$route.name == 'legalAlerts'); },
                },
                {
                  code: 'livros',
                  label: this.fc_getText(null, 'livros', { title: true }),
                  command: () => { this.fc_goRoute('livros'); },
                  disabled: () => { return (this.$route.name == 'livros'); },
                },
                {
                  code: 'ebooks',
                  label: this.fc_getText(null, 'ebooks', { title: true }),
                  command: () => { this.fc_goRoute('ebooks'); },
                  disabled: () => { return (this.$route.name == 'ebooks'); },
                },
                {
                  code: 'outrasPublicacoes',
                  label: this.fc_getText(null, 'outrasPublicacoes', { title: true }),
                  command: () => { this.fc_goRoute('outrasPublicacoes'); },
                  disabled: () => { return (this.$route.name == 'outrasPublicacoes'); },
                },
              ],
            },
          ],
        },
        {
          code: 'servicos',
          label: this.fc_getText(null, 'servicos', { upper: true }),
          items: [
            {
              code: 'focoEmpresa',
              label: this.fc_getText(null, 'focoEmpresa', { upper: true }),
              command: () => { this.fc_goRoute('focoEmpresa'); },
              disabled: () => { return (this.$route.name == 'focoEmpresa'); },
            },
            // {
            //   code: 'registosNotariado',
            //   label: this.fc_getText(null, 'registosNotariado', { upper: true }),
            //   command: () => { this.fc_goRoute('registosNotariado'); },
            //   disabled: () => { return (this.$route.name == 'registosNotariado'); },
            // }
          ],
        },
        {
          code: 'contactos',
          label: this.fc_getText(null, 'contactos', { upper: true }),
          command: () => { this.fc_goRoute('contactos'); },
          disabled: () => { return (this.$route.name == 'contactos'); },
        },
      ]
    };
  },
  props: {
    menus: Object,
    servicosFoco: Object,
    servicosRegistos: Object
  },
  computed: {
    areasCount() {
      return this.menus.areas.length;
    },
  },
  watch: {},
  methods: {
    mt_search() {
      this.fc_openModal({ component: MenuPesquisa, size: 'big', dismissableMask: true, maximizable: true, header: ' ', data: 'background-color= var(--rsa-color);', position: 'center', contentClass: 'dialogComp-pesquisa' });
    },
  },
  created() {},
  mounted() {
    if (this.menus != null) {
      let rede = this.items.find(x => x.code == 'rede');
      if (rede != null) {
        this.menus.redePrincipal.forEach((item, index) => {
          rede.items.push({ label: item.descricao, command: () => { this.fc_goRoute(item.route, { id: item.routeId }); }, disabled: () => { return (this.$route.name == item.route && this.$route.params.id == item.routeId); } });
        });

        if (this.menus.redeParceriaNacional.length > 0) {
          let redeParceriaNacional = rede.items.push({ label: this.fc_getText(null, 'parceriasNacionais', { title: true }), items: [] });
          this.menus.redeParceriaNacional.forEach((item, index) => {
            rede.items[redeParceriaNacional - 1].items.push({ label: item.descricao, command: () => { this.fc_goRoute(item.route, { id: item.routeId }); }, disabled: () => { return (this.$route.name == item.route && this.$route.params.id == item.routeId); } });
          });
        }

        if (this.menus.redeParceriaInternacional.length > 0) {
          let redeParceriaInternacional = rede.items.push({ label: this.fc_getText(null, 'parceriasInternacionais', { title: true }), items: [] });
          this.menus.redeParceriaInternacional.forEach((item, index) => {
            rede.items[redeParceriaInternacional - 1].items.push({ label: item.descricao, command: () => { this.fc_goRoute(item.route, { id: item.routeId }); }, disabled: () => { return (this.$route.name == item.route && this.$route.params.id == item.routeId); } });
          });
        }

        if (this.menus.redeOutras.length > 0) {
          let redeOutras = rede.items.push({ label: this.fc_getText(null, 'outrasParcerias', { title: true }), items: [] });
          this.menus.redeOutras.forEach((item, index) => {
            rede.items[redeOutras - 1].items.push({ label: item.descricao, command: () => { this.fc_goRoute(item.route, { id: item.routeId }); }, disabled: () => { return (this.$route.name == item.route && this.$route.params.id == item.routeId); } });
          });
        }

        if (this.menus.desks.length > 0) {
          rede.items.push( { code: 'desks', label: 'Desks', command: () => { this.fc_goRoute('desks'); },   disabled: () => { return (this.$route.name == 'desks'); } });
        }
        rede.items.push( { code: 'nextlaw', label: 'NextLaw', command: () => { this.fc_goRoute('nextlaw'); },   disabled: () => { return (this.$route.name == 'nextlaw'); } });
      }

      let areas = this.items.find(x => x.code == 'areas');
      if (areas != null && areas.items != null) {
        let areasPrincipais = this.menus.areas.filter((o) => o.areaPrincipal == true);
        let areasComplementares = this.menus.areas.filter((o) => o.areaPrincipal == false);
        if (areasPrincipais.length > 0) {
            areas.items.push({ code: 'areasGroup', label: this.fc_getText(null, 'areasPrincipais').toUpperCase(), command: '', disabled: false });
            areasPrincipais.forEach((item, index) => {
              areas.items.push({ code: 'areaList', label: item.descricao, command: () => { this.fc_goRoute(item.route, { id: item.routeId }); }, disabled: () => { return (this.$route.name == item.route && this.$route.params.id == item.routeId); } });
            });
        }
        if (areasComplementares.length > 0) {
            areas.items.push({ code: 'areasGroup', label: this.fc_getText(null, 'areasComplementares').toUpperCase(), command: '', disabled: false });
            areasComplementares.forEach((item, index) => {
              areas.items.push({ code: 'areaList', label: item.descricao, command: () => { this.fc_goRoute(item.route, { id: item.routeId }); }, disabled: () => { return (this.$route.name == item.route && this.$route.params.id == item.routeId); } });
            });
        }
      }
    }
  },
  unmounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.menuPrincipal.p-menubar {
  background-color: var(--rsa-color);
  color: #fff;
  border-radius: 0px;
  padding: 0rem 0.5rem;
}

.menuPrincipal.p-menubar:deep(.p-menuitem > .p-menuitem-content .p-menuitem-link) {
  padding: 1rem 0.75rem;
  font-size: .75rem;
}
@media screen and (min-width: 576px) {
  .menuPrincipal.p-menubar:deep(.p-menuitem > .p-menuitem-content .p-menuitem-link) {
    font-size: .75rem;
  }
}
@media screen and (min-width: 768px) {
  .menuPrincipal.p-menubar:deep(.p-menuitem > .p-menuitem-content .p-menuitem-link) {
    font-size: .75rem;
  }
}
@media screen and (min-width: 992px) {
  .menuPrincipal.p-menubar:deep(.p-menuitem > .p-menuitem-content .p-menuitem-link) {
    font-size: .875rem;
  }
}
@media screen and (min-width: 1200px) {
  .menuPrincipal.p-menubar:deep(.p-menuitem > .p-menuitem-content .p-menuitem-link) {
    font-size: 1rem;
  }
}

.menuPrincipal.p-menubar:deep(.p-menuitem .p-menuitem-text) {
  font-family: var(--rsa-font-montserrat-semibold);
}

.menuPrincipal.p-menubar:deep(.p-menuitem .p-menuitem-text),
.menuPrincipal.p-menubar:deep(.p-menuitem .p-menuitem-icon),
.menuPrincipal.p-menubar:deep(.p-menuitem .p-submenu-icon) {
  color: #fff;
}

.menuPrincipal.p-menubar:deep(.p-menuitem:not(.p-highlight):not(.p-disabled):hover>.p-menuitem-content .p-menuitem-text),
.menuPrincipal.p-menubar:deep(.p-menuitem:not(.p-highlight):not(.p-disabled):hover>.p-menuitem-content .p-menuitem-icon),
.menuPrincipal.p-menubar:deep(.p-menuitem:not(.p-highlight):not(.p-disabled):hover>.p-menuitem-content .p-submenu-icon) {
  color: var(--rsa-color-02);
}

.menuPrincipal.p-menubar:deep(.p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-text),
.menuPrincipal.p-menubar:deep(.p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-icon),
.menuPrincipal.p-menubar:deep(.p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-submenu-icon) {
  color: var(--rsa-color-02);
}

.menuPrincipal.p-menubar:deep(.p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-text),
.menuPrincipal.p-menubar:deep(.p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-icon),
.menuPrincipal.p-menubar:deep(.p-menuitem.p-highlight>.p-menuitem-content .p-submenu-icon) {
  color: var(--rsa-color-02);
}

.menuPrincipal.p-menubar:deep(.p-menuitem:not(.p-highlight):not(.p-disabled):hover>.p-menuitem-content) {
  background: none;
}

.menuPrincipal.p-menubar:deep(.p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content) {
  background: none;
}

.menuPrincipal.p-menubar:deep(.p-menuitem.p-highlight>.p-menuitem-content) {
  background: none;
}

@media screen and (max-width:1200px) {
  .menuPrincipal.p-menubar:deep(.p-submenu-list:has(.areaList)){
    width: 215px !important;
  }
}
@media screen and (min-width:1200px) {
  .menuPrincipal.p-menubar:deep(.p-submenu-list:has(.areaList)){
    width: 240px !important;
  }
  .menuPrincipal.p-menubar:deep(.p-submenu-list:has(.servicosList)){
    width: 230px !important;
  }
}
@media screen and (max-width:960px) {
  .menuPrincipal.p-menubar:deep(.p-submenu-list:has(.areaList)){
    width: 100% !important;
  }
  .menuPrincipal.p-menubar.p-menubar-mobile-active:deep(.p-menubar-root-list) {
      max-height: 45vh;
      overflow-y: auto;
      flex-direction: row;
  }
}
.menuPrincipal.p-menubar:deep(.p-submenu-list) {
  background-color: var(--rsa-gray-01);
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.menuPrincipal.p-menubar:deep(.p-submenu-list .p-menuitem-link) {
  padding: 0.5rem 0.75rem !important;
  font-size: .75rem !important;
}
@media screen and (min-width: 576px) {
  .menuPrincipal.p-menubar:deep(.p-submenu-list .p-menuitem-link) {
    font-size: .75rem !important;
  }
}
@media screen and (min-width: 768px) {
  .menuPrincipal.p-menubar:deep(.p-submenu-list .p-menuitem-link) {
    font-size: .75rem !important;
  }
}
@media screen and (min-width: 992px) {
  .menuPrincipal.p-menubar:deep(.p-submenu-list .p-menuitem-link) {
    font-size: .75rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .menuPrincipal.p-menubar:deep(.p-submenu-list .p-menuitem-link) {
    font-size: .875rem !important;
  }
}

.menuPrincipal.p-menubar:deep(.p-submenu-list .p-menuitem .p-menuitem-text),
.menuPrincipal.p-menubar:deep(.p-submenu-list .p-menuitem .p-menuitem-icon),
.menuPrincipal.p-menubar:deep(.p-submenu-list .p-menuitem .p-submenu-icon) {
  color: var(--text-color);
  font-weight: normal;
}

.menuPrincipal.p-menubar:deep(.p-submenu-list .p-menuitem:not(.p-highlight):not(.p-disabled):hover>.p-menuitem-content .p-menuitem-text),
.menuPrincipal.p-menubar:deep(.p-submenu-list .p-menuitem:not(.p-highlight):not(.p-disabled):hover>.p-menuitem-content .p-menuitem-icon),
.menuPrincipal.p-menubar:deep(.p-submenu-list .p-menuitem:not(.p-highlight):not(.p-disabled):hover>.p-menuitem-content .p-submenu-icon) {
  color: var(--rsa-color);
}

.menuPrincipal.p-menubar:deep(.p-submenu-list .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-text),
.menuPrincipal.p-menubar:deep(.p-submenu-list .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-icon),
.menuPrincipal.p-menubar:deep(.p-submenu-list .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-submenu-icon) {
  color: var(--rsa-color);
}

.menuPrincipal.p-menubar:deep(.p-submenu-list .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-text),
.menuPrincipal.p-menubar:deep(.p-submenu-list .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-icon),
.menuPrincipal.p-menubar:deep(.p-submenu-list .p-menuitem.p-highlight>.p-menuitem-content .p-submenu-icon) {
  color: var(--rsa-color);
}

.menuPrincipal.p-menubar:deep(.p-menubar-button) {
  color: #fff;
  margin-right: 20px;
}

.menuPrincipal.p-menubar:deep(.p-menubar-button:hover) {
  color: var(--rsa-color-02);
}

.menuPrincipal.p-menubar.p-menubar-mobile-active:deep(.p-menubar-root-list) {
  background-color: var(--rsa-color);
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.menuPrincipal.p-menubar:deep(.p-submenu-list:has(.areaList)){
  padding: 0 !important;
}
.menuPrincipal.p-menubar .p-submenu-list .areasGroup{
  background-color: black !important;
  cursor: default !important;
}
.menuPrincipal.p-menubar .p-submenu-list .areasGroup span{
  color: white !important
}
</style>
